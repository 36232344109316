<template>
  <div class="navbar p-5 bg-white dark:bg-bggrayl dark:text-gray-50">
    <nav class="flex text-center max-w-100 mx-auto my-0 items-center">
      <h1>
        <router-link :to="{ name: 'Users' }">
          <img
            src="../assets/Logo2x.png"
            class="object-scale-down h-48 w-full pl-10 max-h-16"
          />
        </router-link>
      </h1>
      <div class="ml-auto">
        <div v-if="user" class="flex items-center">
          <div class="text-base space-x-6 ...">
            <div class="inline-block ...">
              <router-link :to="{ name: 'Users' }" class="font-bold font-sans"
                >USERS</router-link
              >
            </div>
            <div class="inline-block ...">
              <router-link :to="{ name: 'Units' }" class="font-bold font-sans"
                >LEVELS</router-link
              >
            </div>
            <div class="inline-block ...">
              <router-link :to="{ name: 'Dishes' }" class="font-bold font-sans"
                >DISHES</router-link
              >
            </div>
            <div class="inline-block ...">
              <router-link :to="{ name: 'Recipes' }" class="font-bold font-sans"
                >RECIPES</router-link
              >
            </div>
            <div class="inline-block ...">
               <router-link :to="{ name: 'Feedbacks' }" class="font-bold font-sans"
                >FEEDBACK</router-link
              >
            </div>
             <div class="inline-block ...">
               <router-link :to="{ name: 'mediaLibrary' }" class="font-bold font-sans disable"
                >MEDIA LIBRARY</router-link
              >
            </div>
          </div>
          <div
            @click="toggle"
            class="inline-block pl-7 pr-5 cursor-pointer ..."
          >
            <img src="../assets/logoadm.png" class="object-scale-down w-full" />
            <div class="relative">
              <div
                v-show="isOpen"
                class="absolute left-1 w-48 py-2 mt-2 bg-white bg-gray-100 rounded-md shadow-xl"
              >
                <a
                  @click="handleClick"
                  class="block cursor-pointer px-4 py-2 text-sm text-gray-300 text-gray-700 hover:bg-gray-200 hover:text-white"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-col space-x-1 ...">
            <div class="inline-block ...">
              <p class="font-sans">
                  {{user.displayName}}
              </p>
               <p class="text-mycolorgray">
                  Admin account
              </p>
              </div>
          </div>
        </div>
        <div v-else></div>
      </div>
    </nav>
  </div>
</template>

<script>
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const router = useRouter();
    const handleClick = async () => {
      await logout();
      console.log("logged out");
      router.push({ name: "Login" });
    };
         

    return { handleClick, user };
  },
  methods: {
    toggle: function () {
      this.isOpen = !this.isOpen;
    },
    show: function () {
      this.isOpen = true;
    },
    hide: function () {
      this.isOpen = false;
    },
  },
  computed: {
    currentRouteName() {
      console.log(this.$route.name);
      return this.$route.name;
    },
  },
};
</script>
<style>
.router-link-active,
.router-link-exact-active {
  color: #51a045;
  cursor: pointer;
}
</style>
