<template>
  <div class="w-96 max-w-xl m-auto items-center dark:bg-bggraym dark:text-gray-50">
    <div>
      <img
        src="../assets/logomark-parsnip.svg"
        class="object-scale-down h-48 w-40 w-full ..."
      />
    </div>
    <div>
      <p class="text-6xl dark:text-gray-50 font-sans font-bold">Sign in</p>
    </div>
    <form
      @submit.prevent="handleSubmit"
      class="bg-white rounded pt-6 pb-8 mb-4"
    >
      <div class="mb-6">
        <label
          class="flex dark:text-gray-50 text-gray-700 font-serif text-sm font-bold mb-2"
          for="email"
        >
          Email
        </label>
        <input
          v-model="email"
          class="bg-white font-serif appearance-none border border-black rounded w-96 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
          id="inline-full-name"
          type="text"
          placeholder="Enter your email"
        />
      </div>
      <div class="mb-6">
        <label
          class="flex text-gray-700  dark:text-gray-50 font-serif text-sm font-bold mb-2"
          for="password"
        >
          Password
        </label>
        <input
          v-model="password"
          class="font-serif shadow appearance-none border border-black rounded w-96 py-3 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:border-green-500"
          id="password"
          type="password"
          placeholder="Enter your password"
        />
      </div>
      <div class="w-full">
        <button
          class="w-96 font-sans h-12 px-6 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
        >
          Sign in
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useLogin from "../composables/useLogin";
import { ref } from "vue";
import { useRouter } from "vue-router";
import useLogout from "../composables/useLogout";
import getUser from "@/composables/getUser";
import { createToast } from "mosha-vue-toastify";
import { db } from "../firebase/config";
export default {
  setup() {
    const { error, login, isPending } = useLogin();
    const email = ref("");
    const password = ref("");
    const router = useRouter();
    const { user } = getUser();
    const { logout } = useLogout();
    if (user) {
      router.push({ name: "Units" });
    }
    const handleSubmit = async () => {
      await login(email.value, password.value).then(async (m) => {
        try {
          const isPerm = await db
            .collection("Permissions")
            .get()
            .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
            const ids = isPerm.find((id) => id.id === m.user.uid);
          if (ids) {
            router.push({ name: "Units" });
            console.log("it's production")
          }
        } catch (e) {
        await logout();
          createToast(
            {
              title: "you do not have access permissions",
            },
            {
              type: "danger",
              position: "top-center",
              transition: "bounce",
            }
          );
        }
      });
      if (!error.value) {
        console.log("user logged in");
      }
      if (error.value) {
        createToast(
          {
            title: "Incorect Credentials ",
            description: "check your email or password",
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      }
    };
    return { email, password, handleSubmit, error, isPending };
  },
};
</script>
