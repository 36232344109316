import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import { projectAuth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/users",
    name: "Users",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Main.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/levels",
    name: "Units",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/units"),
    beforeEnter: requireAuth,
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/feedbacks"),
    beforeEnter: requireAuth,
  },
  {
    path: "/userDetail/:id",
    name: "UserDetail",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserDetail.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/userDetail/:id",
    name: "UserDetail",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserDetail.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dish/:id",
    name: "Dish",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dishes/dishesLevelpopup.vue"
      ),
    beforeEnter: requireAuth,
  },
  {
    path: "/mediaLibrary",
    name: "mediaLibrary",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/media-library"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dishes",
    name: "Dishes",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/dishes"),
    beforeEnter: requireAuth,
  },
  {
    path: "/recipes",
    name: "Recipes",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/recipe"),
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
