<template>
  <div class="content dark:bg-bggraym scrollbar-hide">
    <Navbar />
    <router-view />
  </div>
</template>

<style></style>
<script>
import Navbar from "./components/navbar.vue";
export default {
  components: { Navbar },
};
</script>

<style>
@font-face {
  font-family: "FilsonSoft";
  src: url("./assets/fonts/FilsonSoft-Bold.otf") format("opentype");
}
@font-face {
  font-family: "AvenirLTStd";
  src: url("./assets/fonts/AvenirLTStd-Black.otf") format("opentype");
}
.content {
  height: 100vh;
  overflow: scroll;
}
html,
body {
  background: #fcfcfc;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, FilsonSoft, AvenirLTStd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
