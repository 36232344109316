import firebase from "firebase/compat";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  /*  production */
  apiKey: "AIzaSyBigVfB012quGfKxrwQdwKOAOKWh4nGtFU",
  authDomain: "parsnip-io.firebaseapp.com",
  projectId: "parsnip-io",
  storageBucket: "parsnip-io.appspot.com",
  messagingSenderId: "312745864735",
  appId: "1:312745864735:web:dc258d86b5db3f4abb6f2a",
  measurementId: "G-BTQRCCNVT8",
  /* staging */
 /*    apiKey: "AIzaSyCh_9Lh0QhFVdTpEN56gVFIIaaGBFbma5Q",
  authDomain: "parsnip-stg.firebaseapp.com",
  projectId: "parsnip-stg",
  storageBucket: "parsnip-stg.appspot.com",
  messagingSenderId: "54275524591",
  appId: "1:54275524591:web:67d1c3a3b7984510ef581a",
  measurementId: "G-Z6S5VTT533", */
};

firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth();

const db = firebase.firestore();

const projectFirestore = firebase.firestore();

const projectStorage = firebase.storage();

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, timestamp, db, projectStorage };
