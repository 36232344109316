import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { projectAuth } from "./firebase/config";
import "./index.css";
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import NuModal from "nu-modal-vue";
import VueSnap from "vue-snap";
import "vue-snap/dist/vue-snap.css";

let app;

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(require("vue3-shortkey"))
      .use(moshaToast)
      .use(VueSnap)
      .use(router)
      .use(NuModal)
      .mount("#app");
  }
});
